export function headers() {
  return new Headers({
    'Content-Type': 'application/json',
    'X-CSRF-Token': document.getElementsByTagName('meta')['csrf-token'].content
  })
}

function makeRequest(verb, endPoint, data) {
  return fetch(endPoint, {
    headers: headers(),
    credentials: 'include',
    method: verb,
    body: JSON.stringify(data)
  })
}

export function MakeGetRequest(endPoint) {
  return fetch(endPoint)
}

export function MakePostRequest(endPoint, data) {
  return makeRequest('POST', endPoint, data)
}

export function MakePutRequest(endPoint, data) {
  return makeRequest('PUT', endPoint, data)
}

export function MakePatchRequest(endPoint, data) {
  return makeRequest('PATCH', endPoint, data)
}

export function MakeDeleteRequest(endPoint, data) {
  return makeRequest('DELETE', endPoint, data)
}

export function SetupSvg() {
  const svgWrapper = document.getElementById('svg-icons-wrapper')

  // first check if svg-icons-wrapper already contains content
  if (svgWrapper && svgWrapper.childNodes.length === 0) {
    // request sprite sheet to insert into svg-icons-wrapper
    const svgUrl = document.querySelector('body').dataset.svgIconsUrl
    fetch(svgUrl)
      .then((response) => {
        return response.text()
      })
      .then((svg) => {
        document.getElementById('svg-icons-wrapper').innerHTML += svg
      })
  }
}

export function camelize(s) {
  return s.replace(/([-_][a-z])/gi, ($1) => {
    return $1.toUpperCase().replace('-', '').replace('_', '')
  })
}

/**
 * @return {string}
 */
export function NumberAsWord(number) {
  return ['No', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine'][number] || String(number)
}

export function CloneFile(file) {
  // define the attributes that get carried across to the file
  return new File([file], file.name, { type: file.type })
}

export function developmentLog(message, isError = false) {
  const debugMode = document.body.classList.contains('non-production')
  if (debugMode) {
    // eslint-disable-next-line no-console
    isError ? console.error(message) : console.log(message)
  }
}
export function authenticityToken() {
  return document.querySelector('meta[name="csrf-token"]').content
}
